
import { Component, Prop, Vue } from 'vue-property-decorator'
import Overlay from '@/components/Overlay.vue'
import BaseIcon from '@/components/base/BaseIcon.vue'

@Component({
  name: 'Popup',
  components: {
    Overlay,
    BaseIcon,
  },
})
export default class Popup extends Vue {
  @Prop({ default: true })
  show!: boolean

  @Prop({ default: '' })
  title!: string
}
