
import { Component, Prop, Vue } from 'vue-property-decorator'
import BaseIcon from '@/components/base/BaseIcon.vue'

@Component({
  name: 'Menu',
  components: { BaseIcon },
})
export default class Menu extends Vue {
  @Prop(Boolean)
  small!: boolean

  menus = [
    { id: 1, title: 'Моя анкета', path: '/main', icon: 'profile-data' },
    // { id: 2, title: 'Мои адреса', path: '/my-locations', icon: 'maps' },
    { id: 3, title: 'Статистика', path: '/stats', icon: 'statistics' },
    // { id: 3, title: 'Спец. цены', path: '/special-price', icon: 'special-price' },
    // { id: 4, title: 'Календарь', path: '/calendar', icon: 'calendar' },
    // { id: 5, title: 'Клиенты', path: '/clients', icon: 'clients' },
    { id: 6, title: 'Заявки', path: '/events', icon: 'orders' },
    { id: 7, title: 'Профиль', path: '/profile', icon: 'profile' },
    // { id: 8, title: 'Премиум', path: '/premium', icon: 'premium' },
  ]
}
