
import { Component, Vue, Prop } from 'vue-property-decorator'

@Component({
  name: 'Overlay',
})
export default class Overlay extends Vue {
  @Prop({ default: true })
  show!: boolean

  hideHtmlScroll =
    '<style>html { overflow-y: hidden } body { overflow-y: scroll }</style>'
}
