
import { Component, Vue } from 'vue-property-decorator'
import breakpoints from '@/plugins/breakpoints'

import BaseButton from '@/components/base/BaseButton.vue'
import BaseIcon from '@/components/base/BaseIcon.vue'
import Menu from '@/layouts/components/Menu.vue'
import Hint from '@/layouts/components/Hint.vue'
import Toggler from '@/layouts/components/Toggler.vue'
import DepositInvoice from '@/modules/components/DepositInvoice.vue'
import { UserModel } from '@/core/models/UserModel'
import { TippyComponent } from 'vue-tippy/dist/vue-tippy.esm'

import { namespace } from 'vuex-class'
const UserStore = namespace('UserStore')
import { toLocalCurrency } from '@/utils/currencyFormatter'

@Component({
  name: 'Drawer',
  components: {
    BaseButton,
    BaseIcon,
    Menu,
    Hint,
    Toggler,
    DepositInvoice,
    TippyComponent,
  },
})
export default class Drawer extends Vue {
  openMobileMenu = false
  breakpoints = breakpoints
  showDepositInvoice = false
  toLocalCurrency = toLocalCurrency

  @UserStore.Getter
  public currentUser!: UserModel

  get isSmall(): boolean {
    return breakpoints.width <= 1366
  }

  onClickOutside({ target }: Event): void {
    if (!(target instanceof Element && target.closest('#mobileMenuToggler'))) {
      this.openMobileMenu = false
    }
  }
}
